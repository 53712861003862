import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from '@angular/forms';
import {
  COMMON_BRANDS,
  POWER_TYPES,
  REASON_TYPES
} from 'src/app/shared/app-configuration/app-configuration';
import { GlobalVariables } from 'src/app/shared/global-variables';
import { FilterRequest } from 'src/app/shared/models/filterRequest.model';
import { CardataService } from 'src/app/shared/services/cardata.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'sco-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {
  form: FormGroup;

  brands: Array<string> = [];
  models: Array<any> = null;

  mostcommonBrands = COMMON_BRANDS;
  powerTypes = POWER_TYPES;
  reasonTypes = REASON_TYPES;

  brand = new FormControl(null, [Validators.required]);
  model = new FormControl(null, [Validators.required]);
  otherModel = new FormControl(null);
  mileage = new FormControl(null, [
    Validators.max(250000),
    Validators.required
  ]);
  reason = new FormControl(null, [Validators.required]);
  firstRegistration = new FormControl(null, [Validators.required]);
  maxFirstRegistrationDate = new Date();
  enginePower = new FormControl(null, [Validators.required]);
  powerType = new FormControl(this.powerTypes[0]);
  checkbook = new FormControl(false);
  generalInspection = new FormControl(false);

  constructor(
    private formBuilder: FormBuilder,
    private cardataService: CardataService,
    private dataService: DataService,
    private globals: GlobalVariables
  ) {
    this.form = new FormGroup({});
  }

  ngOnInit() {
    this.getBrands();

    this.form = this.formBuilder.group({
      brand: this.brand,
      model: this.model,
      otherModel: this.otherModel,
      mileage: this.mileage,
      firstRegistration: this.firstRegistration,
      reason: this.reason,
      enginePower: this.enginePower,
      powerType: this.powerType,
      checkbook: this.checkbook,
      generalInspection: this.generalInspection
    });

    const filterRequest = this.dataService.getFilterRequest();
    if (filterRequest) {
      this.getModels(filterRequest.brand); // Get Models before setting the values
      this.form.setValue(filterRequest);
    }
  }

  getBrands() {
    this.cardataService.getMakes().subscribe(brands => {
      const mostCommonBrands = [];
      this.brands = brands.filter(brand => {
        // Filter out most common brands
        if (this.mostcommonBrands.indexOf(brand['make']) !== -1) {
          mostCommonBrands.push(brand);
        }

        return brand;
      });

      // Add spacer for disabling
      mostCommonBrands.push({
        make_id: null,
        make: '---'
      });
      this.brands.unshift(...mostCommonBrands);
    });
  }

  getModels(brand) {
    this.model.setValue(null);
    this.model.markAsUntouched();
    this.models = null;

    this.cardataService.getModels(brand['make_id']).subscribe(models => {
      this.models = models;

      // Add other field in case the user can't find his model
      this.models.push({
        model: '- Anderes Modell -',
        make_id: null,
        model_id: null
      });
    });
  }

  getErrorMessage(field: FormControl, fieldName?: String) {
    return field.hasError('required')
      ? 'Dieses Feld wird benötigt'
      : field.hasError('email')
      ? 'Die Email-Addresse ist ungültig'
      : field.hasError('max') && fieldName === 'age'
      ? 'Das Fahrzeug darf nicht älter als 12 Jahre alt sein'
      : field.hasError('max') && fieldName === 'mileage'
      ? 'Das Fahrzeug darf nicht mehr als 250.000 km haben'
      : '';
  }

  public compareById(object1, object2) {
    if (!object1 || !object2) {
      return;
    }

    if (object1.id === object2.id) {
      return true;
    }
    return false;
  }

  public compareBrandsById(object1, object2) {
    if (!object1 || !object2) {
      return;
    }

    if (object1.make_id === object2.make_id) {
      return true;
    }
    return false;
  }

  public compareModelById(object1, object2) {
    if (!object1 || !object2) {
      return;
    }

    if (object1.model_id === object2.model_id) {
      return true;
    }
    return false;
  }

  public filterServices() {
    this.globals.setAvailableServices(
      this.dataService.getAvailableServicesForSearchData(this.form
        .value as FilterRequest)
    );
    this.dataService.setFilterRequest(this.form.value as FilterRequest);
  }
}
