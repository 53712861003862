import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sco-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss']
})
export class AdvantagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
