import { Component, OnInit, Input } from '@angular/core';
import { Service } from '../../models/service.model';
import { SERVICES } from '../../app-configuration/app-configuration';
import { DataService } from '../../services/data.service';

@Component({
  selector: 'sco-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit {
  @Input()
  currentService: Service;

  additionalServices: Array<Service>;

  constructor(private dataService: DataService) {}

  ngOnInit() {
    if (this.currentService) {
      this.additionalServices = SERVICES.filter(
        service => service.id !== this.currentService.id
      );
    }
  }
}
