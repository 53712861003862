import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'sco-data-protection',
  templateUrl: './data-protection.component.html',
  styleUrls: ['./data-protection.component.scss']
})
export class DataProtectionComponent implements OnInit {
  constructor(private snackBar: MatSnackBar) {}

  ngOnInit() {}

  // Opt-out function
  gaOptout() {
    const disableStr = 'ga-disable-UA-128945527-1';
    document.cookie =
      disableStr + '=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/';
    window[disableStr] = true;

    this.snackBar.open(
      'Die Erfassung deiner Nutzerdaten ist nun deaktiviert.',
      'OK',
      {
        duration: 3000
      }
    );
  }
}
