import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  MatCardModule,
  MatCheckboxModule,
  MatProgressBarModule,
  MatSelectModule,
  MAT_DATE_LOCALE
} from '@angular/material';
import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS
} from '@angular/material-moment-adapter';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes
} from '@angular/router';
import { MatVideoModule } from 'mat-video';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { AppComponent } from './app.component';
import { FooterComponent } from './core/footer/footer.component';
import { HeaderModule } from './core/header/header.module';
import { SpinnerDialogComponent } from './shared/components/dialogs/spinner-dialog/spinner-dialog.component';
import { BuddyComponent } from './pages/checkout/buddy/buddy.component';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { ConfirmationComponent } from './pages/checkout/confirmation/confirmation.component';
import { FilterFormComponent } from './pages/checkout/filter-form/filter-form.component';
import { PaymentComponent } from './pages/checkout/payment/payment.component';
import { VinpriceCheckComponent } from './pages/checkout/vinprice-check/vinprice-check.component';
import { DataProtectionComponent } from './pages/data-protection/data-protection.component';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/home/sections/about/about.component';
import { AdvantagesComponent } from './pages/home/sections/advantages/advantages.component';
import { BlogComponent } from './pages/home/sections/blog/blog.component';
import { ContactComponent } from './pages/home/sections/contact/contact.component';
import { DetailsComponent } from './pages/home/sections/details/details.component';
import { IntroComponent } from './pages/home/sections/intro/intro.component';
import { ServiceOverviewComponent } from './pages/home/sections/services/service-overview.component';
import { ImprintComponent } from './pages/imprint/imprint.component';
import { BuddyDetailsComponent } from './pages/product-details/buddy-details/buddy-details.component';
import { UploadComponent } from './pages/upload/upload.component';
import { ProductStepperHostDirective } from './shared/directives/product-stepper-host.directive';
import { SharedModule } from './shared/shared.module';
import { PaymentDetailsComponent } from './pages/product-details/payment-details/payment-details.component';
import { RegistrationDetailsComponent } from './pages/product-details/registration-details/registration-details.component';
import { CarRegistrationComponent } from './pages/checkout/car-registration/car-registration.component';
import { SocialMediaBarComponent } from './core/social-media-bar/social-media-bar.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'impressum', component: ImprintComponent },
  { path: 'datenschutz', component: DataProtectionComponent },
  { path: 'agb', component: TermsAndConditionsComponent },
  { path: 'beratung', component: BuddyDetailsComponent },
  { path: 'bezahlung', component: PaymentDetailsComponent },
  { path: 'zulassung', component: RegistrationDetailsComponent },
  {
    path: 'checkout',
    component: CheckoutComponent,
    children: [
      {
        path: 'beratung',
        component: BuddyComponent,
        children: [
          {
            path: 'payment-success',
            component: BuddyComponent
          },
          {
            path: 'payment-cancel',
            component: BuddyComponent
          }
        ]
      },
      {
        path: 'bezahlung',
        component: PaymentComponent,
        children: [
          {
            path: 'payment-success',
            component: PaymentComponent
          },
          {
            path: 'payment-cancel',
            component: PaymentComponent
          }
        ]
      },
      {
        path: 'zulassung',
        component: CarRegistrationComponent
      }
    ]
  },
  {
    path: 'kaufvertrag',
    component: UploadComponent
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  preloadingStrategy: PreloadAllModules,
  onSameUrlNavigation: 'reload',
  scrollPositionRestoration: 'disabled',
  scrollOffset: [0, 64]
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AboutComponent,
    AdvantagesComponent,
    ContactComponent,
    FooterComponent,
    IntroComponent,
    ImprintComponent,
    DataProtectionComponent,
    SpinnerDialogComponent,
    UploadComponent,
    BuddyComponent,
    ServiceOverviewComponent,
    FilterFormComponent,
    PaymentComponent,
    VinpriceCheckComponent,
    ProductStepperHostDirective,
    ConfirmationComponent,
    DetailsComponent,
    BlogComponent,
    BuddyDetailsComponent,
    CheckoutComponent,
    PaymentDetailsComponent,
    RegistrationDetailsComponent,
    CarRegistrationComponent,
    SocialMediaBarComponent,
    TermsAndConditionsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forRoot(appRoutes, routerOptions),
    NgxPageScrollModule,
    HeaderModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatSnackBarModule,
    MatSelectModule,
    MatStepperModule,
    MatRadioModule,
    MatSliderModule,
    HttpClientModule,
    SharedModule,
    MatProgressBarModule,
    MatTabsModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatVideoModule
  ],
  providers: [
    MatDatepickerModule,
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_DATE_LOCALE, useValue: 'de-DE' }
  ],

  entryComponents: [
    SpinnerDialogComponent,
    PaymentComponent,
    VinpriceCheckComponent,
    BuddyComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
