import { Component, OnInit, ViewChild } from '@angular/core';
import { ContactService } from '../../../../shared/services/contact.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { REGULAR_EXPRESSIONS } from 'src/app/shared/app-configuration/app-configuration';
import { ErrorService } from 'src/app/shared/services/error.service';

@Component({
  selector: 'sco-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  @ViewChild('f') form;

  name = new FormControl('', [Validators.required]);
  email = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.pattern(REGULAR_EXPRESSIONS.email)
    ])
  );
  message = new FormControl('', [Validators.required]);

  mailForm: FormGroup = new FormGroup({
    name: this.name,
    email: this.email,
    message: this.message
  });

  constructor(
    private contactService: ContactService,
    private snackBar: MatSnackBar,
    public errorService: ErrorService
  ) {}

  ngOnInit() {}

  onFormSubmit() {
    const data = {
      name: this.name.value,
      email: this.email.value,
      message: this.message.value
    };

    this.contactService.postMails(data).subscribe(
      res => {
        this.snackBar.open(
          'Wir haben deine Nachricht empfangen. Vielen Dank!',
          'OK',
          {
            duration: 3000
          }
        );

        this.form.resetForm();
      },
      err => {
        this.snackBar.open('Uups, da ist etwas schief gegangen.');
      }
    );
  }
}
