export class Service {
  id: string;
  name: string;
  price: string;
  warranty: Boolean;
  description: string;
  component: string;
  thumbnail: string;
  detailUrl: string;
  bookingUrl: string;
  active: boolean;
  apiEndpoint: string;
}
