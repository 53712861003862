import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sco-social-media-bar',
  templateUrl: './social-media-bar.component.html',
  styleUrls: ['./social-media-bar.component.scss']
})
export class SocialMediaBarComponent implements OnInit {
  desc = encodeURIComponent(
    'Scoop ist dein Ansprechpartner für einen sicheren Autokauf im Gebrauchtwagenmarkt und hilft Dir dein Traumauto zu finden, zu bezahlen und anzumelden.'
  );

  url = 'https://getscoop.de';
  title = 'Gebrauchtwagenkauf – Sicher. Einfach. Transparent.';
  article =
    'Haben Sie schon einmal einen Gebrauchtwagen von einer Privatperson gekauft? Sicherlich kennen Sie die ein oder andere abschreckende Geschichte über versteckte Schäden oder unseriöse Barzahlungen. Das Angebot mag noch so attraktiv sein: Wenn die Rahmenbedingungen Sie skeptisch machen, verzichten Sie wahrscheinlich auf den Kauf. SCOOP kann das jetzt ändern. Die neuen Services sollen die Risiken beim Kauf von Gebrauchtwagen von Privatpersonen minimieren.';

  constructor() {}

  ngOnInit() {}

  shareOnFacebook() {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=https://getscoop.de',
      'facebook-dialog',
      this.getWindowOptions()
    );
    return false;
  }

  shareOnTwitter() {
    window.open(
      'https://twitter.com/intent/tweet?url=https://getscoop.de&text=' +
        this.title +
        ' ' +
        this.desc,
      'twitter-dialog',
      this.getWindowOptions()
    );
    return false;
  }

  shareOnLinkedIn() {
    window.open(
      'https://www.linkedin.com/shareArticle?mini=true&url=' +
        this.url +
        '&title=' +
        this.title +
        '&summary=' +
        this.desc +
        '&source=scoop',
      'linkedIn-dialog'
    );
    return false;
  }

  getWindowOptions = function() {
    const width = 500;
    const height = 350;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    return [
      'resizable,scrollbars,status',
      'height=' + height,
      'width=' + width,
      'left=' + left,
      'top=' + top
    ].join();
  };
}
