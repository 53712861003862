import { SelectOption } from "../models/selectOption.model";
import { Service } from "../models/service.model";

export const POWER_TYPES: Array<SelectOption> = [
  {
    label: "PS",
    id: 0,
  },
  {
    label: "KW",
    id: 1,
  },
];

export const REASON_TYPES: Array<SelectOption> = [
  {
    label: "... werde ich in Kürze kaufen",
    id: 0,
  },
  //   {
  //     label: '... habe ich kürzlich gekauft',
  //     id: 1
  //   },
  {
    label: "... werde ich demnächst verkaufen",
    id: 2,
  },
  //   {
  //     label: '... besitze ich schon länger',
  //     id: 3
  //   },
  //   {
  //     label: '... weder noch',
  //     id: 4
  //   }
];

export const COMMON_BRANDS = ["Mercedes-Benz", "Volkswagen", "BMW", "Audi"];

export enum TeaserType {
  booking = "BOOKING",
  details = "DETAILS",
}

export const SERVICES: Array<Service> = [
  {
    id: "0",
    name: "Security Package",
    price: "ab € 24,90",
    warranty: false,
    description:
      "Hol dir vor dem Fahrzeugkauf das Security Package: Gebrauchtwagengarantie, geprüfte technische Checkliste, rechtssicherer Kaufvertrag, Online Background-Check.",
    component: "BuddyComponent",
    thumbnail: "services_buchen_beratung",
    active: true,
    detailUrl: "#",
    bookingUrl: "#",
    apiEndpoint: "#",
  },
  {
    id: "1",
    name: "Bezahlung",
    price: "€ 99,90",
    warranty: false,
    description:
      "Mit unserem Treuhand-Bezahl-Service bist du immer auf der sicheren Seite. Bezahle schnell, flexibel und risikofrei dein Traumauto.",
    component: "PaymentComponent",
    thumbnail: "services_buchen_bezahlung",
    active: true,
    detailUrl: "#",
    bookingUrl: "#",
    apiEndpoint: "#",
  },
  {
    id: "2",
    name: "Zulassung",
    price: "ab € 139,00",
    warranty: false,
    description:
      "Mit dem Scoop Zulassungs-Service entfallen lästige Behördengänge und überflüssige Wartezeiten.",
    component: "VinpriceCheckComponent",
    thumbnail: "services_buchen_zulassung",
    active: true,
    detailUrl: "#",
    bookingUrl: "#",
    apiEndpoint: "#",
  },
];

export const REGULAR_EXPRESSIONS = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: /^(\+[0-9]{1,3}|0)[0-9]{3}( ){0,1}[0-9]{4,12}\b/,
};

export const BUDDY_PPRODUCT_TYPES = [
  {
    title: "Fahrzeugberater",
    subtitle: "Hilfe bei der Vorauswahl",
    description:
      "Damit du deinem Traumauto einen Schritt näher kommst, helfen wir dir in einem Vorgespräch Antworten auf all deine Fragen zu bekommen. Wir unterstützen dich dabei die richtige Vorauswahl zu treffen, damit du dir nur die Fahrzeuge anschauen musst, bei denen es sich wirklich lohnt.",
    price: "€ 24,90",
    sku: "sku_FARlYUHGP9YAUz",
    productType: "CONSULTANT",
  },
  {
    title: "Zweites Paar Augen",
    subtitle: "Dein virtueller Begleiter",
    description:
      "Wir gehen mit dir per Telefon unsere geprüfte Checkliste durch und machen dich auf bekannte “Kinderkrankheiten” einzelner Fabrikate aufmerksam. So erhälst du bereits vor Ort einen guten Überblick über den Zustand des Fahrzeugs. Selbstverständlich unterstützen wir dich auch bei deiner finalen Entscheidung und stehen dir auch nach deiner Besichtigung für Rückfragen zur Verfügung.",
    price: "€ 34,90",
    sku: "sku_FARlvRVFoLaXsW",
    productType: "TEST_DRIVE",
  },
];

export const PAYMENT_PPRODUCT = {
  title: "Paylax Gutschein",
  subtitle: "Treuhandzahlung",
  description:
    "Ut aliqua adipisicing deserunt mollit laboris proident sit aliquip aliquip magna tempor laboris amet veniam.",
  price: "€ 99,90",
  sku: "sku_FIO7wPrfxEi9Hb",
};
