import { Injectable } from '@angular/core';
import { SERVICES } from '../app-configuration/app-configuration';
import { FilterRequest } from '../models/filterRequest.model';
import { BehaviorSubject } from 'rxjs';
import { Service } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  filterRequest: FilterRequest;

  constructor() {}

  services = SERVICES;

  getAvailableServicesForSearchData(carData: FilterRequest) {
    // Warranty: Check warranty conditions
    if (
      carData.mileage > 150000 ||
      this.calculateAge(carData.firstRegistration) > 7 ||
      carData.enginePower > 250
    ) {
      // Remove warranty if search data doesn't meet the requirements
      for (let i = 0; i < this.services.length; i++) {
        this.services[i].warranty = false;
      }
    }

    // TODO: Add further restrictions to the other services

    return this.services;
  }

  private calculateAge(registationDate: Date) {
    const ageDifferenceInMillies =
      Date.now() - new Date(registationDate).getTime();
    const ageDate = new Date(ageDifferenceInMillies); // miliseconds from epoch

    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  public getSelectedProduct() {
    return JSON.parse(sessionStorage.getItem('selectedProduct'));
  }

  public setSelectedProduct(product) {
    sessionStorage.setItem('selectedProduct', JSON.stringify(product));
  }

  public getServiceById(id: string) {
    return this.services.find(function(el) {
      return el.id === id;
    });
  }

  public getFilterRequest() {
    if (this.filterRequest) {
      return this.filterRequest;
    } else {
      return JSON.parse(sessionStorage.getItem('request'));
    }
  }

  public setFilterRequest(request: FilterRequest) {
    this.filterRequest = request;
    sessionStorage.setItem('request', JSON.stringify(request));
  }
}
