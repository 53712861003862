import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/shared/models/service.model';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'sco-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class ConfirmationComponent implements OnInit {
  currentService: Service;
  constructor() {}

  ngOnInit() {}
}
