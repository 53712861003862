import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatListModule,
  MatIconModule,
  MatExpansionModule
} from '@angular/material';

import { MatInputModule } from '@angular/material/input';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TeaserComponent } from './components/teaser/teaser.component';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { TruncatePipe } from './directives/pipes/truncate.pipe';
import { RouterModule } from '@angular/router';
import { FaqComponent } from './components/faq/faq.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { FaqBuddyComponent } from './components/faq/faq-buddy/faq-buddy.component';
import { FaqPaymentComponent } from './components/faq/faq-payment/faq-payment.component';
import { FaqCarRegistrationComponent } from './components/faq/faq-car-registration/faq-car-registration.component';

@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatInputModule,
    RouterModule
  ],
  declarations: [
    TeaserComponent,
    SuggestionsComponent,
    TruncatePipe,
    FaqComponent,
    StepperComponent,
    FaqBuddyComponent,
    FaqPaymentComponent,
    FaqCarRegistrationComponent
  ],
  exports: [
    MatIconModule,
    MatButtonModule,
    MatListModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatInputModule,
    TeaserComponent,
    SuggestionsComponent,
    RouterModule,
    FaqComponent,
    FaqBuddyComponent,
    FaqPaymentComponent,
    FaqCarRegistrationComponent
  ]
})
export class SharedModule {}
