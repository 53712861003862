import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sco-car-registration',
  templateUrl: './car-registration.component.html',
  styleUrls: ['./car-registration.component.scss']
})
export class CarRegistrationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
