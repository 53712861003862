import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Service } from './models/service.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalVariables {
  private availableServices: Subject<Array<Service>> = new Subject();
  constructor() {}

  getAvailableServices(): Observable<Array<Service>> {
    return this.availableServices.asObservable();
  }

  setAvailableServices(data: Array<Service>) {
    this.availableServices.next(data);
  }
}
