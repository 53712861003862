import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'sco-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;
  isHome = true;
  constructor(private router: Router, private route: ActivatedRoute) {}

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.checkUrl(window.location.pathname);
      }
    });
  }

  // Check whether current location is a sub page
  checkUrl(url) {
    // console.log('path: ', window.location.pathname);
    if (url === '/') {
      this.isHome = true;
    } else {
      this.isHome = false;
    }
  }
}
