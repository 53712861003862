import { Component, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { AbstractControl, FormGroup } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { Service } from '../../models/service.model';
import { DataService } from '../../services/data.service';
import { ErrorService } from '../../services/error.service';

@Component({
  selector: 'sco-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  form: FormGroup;

  isMobile = false;
  bookingCompleted = false;

  get formArray(): AbstractControl | null {
    return this.form.get('formArray');
  }

  constructor(
    private mediaObserver: MediaObserver,
    public errorService: ErrorService,
    protected dataService: DataService
  ) {
    this.mediaObserver.media$.subscribe(mediaQuery => {
      if (mediaQuery.mqAlias === 'xs' || mediaQuery.mqAlias === 'sm') {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    });
  }

  ngOnInit() {}

  public onStepperSelectionChange(event) {
    window.dataLayer = window.dataLayer || [];

    const product = this.dataService.getSelectedProduct();

    window.dataLayer.push({
      event: `${product.name.toLowerCase()}-step-${
        // TODO: Get rid of .toLowerCase()
        event.selectedIndex
      }-complete`
    });

    // Scroll to the selected step
    // Disable function for IE TODO: Find a fix for IE
    if (this.isIE() || !this.isMobile) {
      return;
    }

    const stepId = this.stepper._getStepLabelId(event.selectedIndex);
    const stepElement = document.getElementById(stepId);
    if (stepElement) {
      setTimeout(() => {
        const topOfElement = stepElement.offsetTop - 140;
        window.scroll({ top: topOfElement, behavior: 'smooth' });
      }, 250);
    }
  }

  protected isIE() {
    const ua = window.navigator.userAgent; // Check the userAgent property of the window.navigator object
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); // IE 11

    return msie > 0 || trident > 0;
  }

  public compareById(object1, object2) {
    if (!object1 || !object2) {
      return;
    }

    if (object1.id === object2.id) {
      return true;
    }
    return false;
  }
}
