import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  constructor() {}

  public getInputErrorMessage(field: FormControl, fieldName?: String) {
    return field.hasError('pattern') && fieldName === 'email'
      ? 'Die Email-Addresse ist ungültig'
      : field.hasError('pattern') && fieldName === 'phone'
      ? 'Die Telefonnummer ist ungültig'
      : field.hasError('max') && fieldName === 'age'
      ? 'Das Fahrzeug darf nicht älter als 12 Jahre alt sein'
      : field.hasError('max') && fieldName === 'mileage'
      ? 'Das Fahrzeug darf nicht mehr als 250.000 km haben'
      : field.hasError('required') && fieldName === 'selectedBuddyProduct'
      ? 'Bitte wähle eine Option'
      : 'Dieses Feld wird benötigt';
  }
}
