import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from '../../models/service.model';

@Component({
  selector: 'sco-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.scss']
})
export class TeaserComponent implements OnInit {
  @Input()
  service: Service;

  @Input()
  isBooking = false;

  currentService: Service;
  constructor() {}

  ngOnInit() {}
}
