import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CardataService {
  constructor() {}

  getMakes(): Observable<any> {
    const ref = firebase.firestore().collection('car_makes');

    return new Observable(observer => {
      ref
        .where('isCommon', '==', true)
        .orderBy('make', 'asc')
        .get()
        .then(snapshot => {
          const docs = snapshot.docs.map(doc => doc.data());
          observer.next(docs);
        });
    });
  }

  getModels(makeId): Observable<any> {
    const ref = firebase.firestore().collection('car_models');

    return new Observable(observer => {
      ref
        .where('make_id', '==', makeId)
        .orderBy('model', 'asc')
        .get()
        .then(snapshot => {
          const docs = snapshot.docs.map(doc => doc.data());
          observer.next(docs);
        });
    });
  }
}
