import { Component, OnInit, AfterViewInit } from '@angular/core';
import { PageScrollConfig } from 'ngx-page-scroll';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import 'firebase/functions';

const settings = {};
const config = environment.firebaseConfig;

@Component({
  selector: 'sco-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'Scoop';
  model = {
    left: true,
    middle: false,
    right: false
  };
  constructor(private router: Router) {
    PageScrollConfig.defaultScrollOffset = 50;
    PageScrollConfig.defaultEasingLogic = {
      ease: (t: number, b: number, c: number, d: number): number => {
        // easeInOutExpo easing
        if (t === 0) {
          return b;
        }
        if (t === d) {
          return b + c;
        }
        if ((t /= d / 2) < 1) {
          return (c / 2) * Math.pow(2, 10 * (t - 1)) + b;
        }
        return (c / 2) * (-Math.pow(2, -10 * --t) + 2) + b;
      }
    };
  }

  ngOnInit() {
    const app = firebase.initializeApp(config);
    app.functions().useFunctionsEmulator('http://localhost:5000');

    firebase.firestore().settings(settings);
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  ngAfterViewInit() {
    // Trigger page view for every route change
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        window.dataLayer.push({
          event: 'virtualPageview',
          'Page Path': event.urlAfterRedirects
        });
      }
    });
  }
}
