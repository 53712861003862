import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatChipList } from '@angular/material';
import * as firebase from 'firebase';
import * as moment from 'moment';
import {
  BUDDY_PPRODUCT_TYPES,
  REGULAR_EXPRESSIONS
} from 'src/app/shared/app-configuration/app-configuration';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { DataService } from 'src/app/shared/services/data.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { Service } from 'src/app/shared/models/service.model';

declare global {
  interface Window {
    dataLayer?: Array<any>;
  }
}

@Component({
  selector: 'sco-buddy',
  templateUrl: './buddy.component.html',
  styleUrls: [
    '../../../shared/components/stepper/stepper.component.scss',
    './buddy.component.scss'
  ]
})
export class BuddyComponent extends StepperComponent
  implements OnInit, AfterViewInit {
  @ViewChild('chipList') chipList: MatChipList;

  buddyProducts = BUDDY_PPRODUCT_TYPES;
  selectedBuddyProduct = new FormControl(null, [Validators.required]);

  appointmentDate = new FormControl(null, [Validators.required]);
  appointmentTime = new FormControl(null, [Validators.required]);
  appointmentMinDate = moment(new Date()).add(2, 'days');
  availableDates: Array<String> = [];
  timeslots: Array<String>;

  firstName = new FormControl(null, [Validators.required]);
  lastName = new FormControl(null, [Validators.required]);
  email = new FormControl(null, [
    Validators.required,
    Validators.pattern(REGULAR_EXPRESSIONS.email)
  ]);
  phone = new FormControl(null, [
    Validators.required,
    Validators.pattern(REGULAR_EXPRESSIONS.phone)
  ]);
  termsCheckbox = new FormControl(null, [Validators.requiredTrue]);
  currentService: Service;

  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    mediaObserver: MediaObserver,
    errorService: ErrorService,
    dataService: DataService
  ) {
    super(mediaObserver, errorService, dataService);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          selectedBuddyProduct: this.selectedBuddyProduct
        }),
        this.formBuilder.group({
          appointmentDate: this.appointmentDate,
          appointmentTime: this.appointmentTime
        }),
        this.formBuilder.group({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone
        }),
        this.formBuilder.group({
          termsCheckbox: this.termsCheckbox
        })
      ])
    });
    this.getAllAvailableDates();

    // Get the selected product
    const product = this.dataService.getSelectedProduct();
    if (product) {
      this.currentService = product;
    }
  }

  ngAfterViewInit() {
    // Prefill form if data exists
    const formData = JSON.parse(sessionStorage.getItem('buddy'));
    if (formData) {
      const date = moment(formData['formArray'][1]['appointmentDate']);

      this.getTimeslotsForDate(date).then(() => {
        this.form.setValue(JSON.parse(sessionStorage.getItem('buddy')));
        if (window.location.pathname.endsWith('/payment-success')) {
          this.bookingCompleted = true;
          this.stepper.linear = false;
          this.stepper.selectedIndex = 4;
          this.stepper.linear = true;
          sessionStorage.clear();
          window.history.replaceState('', '', '/checkout/beratung');
        } else if (window.location.pathname.endsWith('/payment-cancel')) {
          this.stepper.linear = false;
          this.stepper.selectedIndex = 3;
          this.stepper.linear = true;
          window.history.replaceState('', '', '/checkout/beratung');
        }
      });
    }
  }

  private getAllAvailableDates() {
    this.availableDates = [];

    firebase
      .firestore()
      .collection('buddy_timeslots')
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          this.availableDates.push(doc.id);
        });
      });
  }

  private getTimeslotsForDate(dateObject: moment.Moment) {
    this.timeslots = null;

    const date = dateObject.format('D-M-YYYY');

    return new Promise((resolve, reject) => {
      const ref = firebase
        .firestore()
        .collection('buddy_timeslots')
        .doc(date);

      ref.get().then(doc => {
        if (doc.exists) {
          this.timeslots = doc.data().timeslots;
          resolve();
        } else {
          this.timeslots = [];
          reject();
        }
      });
    });
  }

  public onBuddyProductClick(buddyProduct: any) {
    this.selectedBuddyProduct.markAsDirty();
    this.selectedBuddyProduct.setValue(buddyProduct);
    this.stepper.next();
  }

  public onDateInput() {
    this.appointmentTime.reset();
    const date = moment(this.appointmentDate.value);
    this.getTimeslotsForDate(date);
  }

  public dateFilter(d: moment.Moment) {
    const date = d.format('D-M-YYYY'); // Format date to match with ids in firebase
    return this.availableDates.indexOf(date) !== -1;
  }

  public validateAppointmentTime() {
    this.chipList.errorState = this.appointmentTime.hasError('required');
    this.chipList.required = this.appointmentTime.hasError('required');
  }

  public onTimeChipClick(timeslot: String) {
    this.appointmentTime.setValue(timeslot);
    this.validateAppointmentTime();
  }

  public onPaymentClick() {
    if (this.termsCheckbox.invalid) {
      this.form.markAsDirty();
      return;
    }

    sessionStorage.setItem('buddy', JSON.stringify(this.form.value));

    const requestObject = {
      payment: {
        paymentType: 'STRIPE'
      },
      customer: {
        email: this.email.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value,
        phone: this.phone.value
      },
      product: {
        productType: this.selectedBuddyProduct.value.productType,
        appointmentDate: this.appointmentDate.value,
        appointmentTime: this.appointmentTime.value
      }
    };

    this.stripeService.redirectToStripe(
      requestObject,
      this.selectedBuddyProduct.value.sku,
      this.dataService.getSelectedProduct()
    );
  }
}
