import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  PAYMENT_PPRODUCT,
  REGULAR_EXPRESSIONS
} from 'src/app/shared/app-configuration/app-configuration';
import { StepperComponent } from 'src/app/shared/components/stepper/stepper.component';
import { DataService } from 'src/app/shared/services/data.service';
import { ErrorService } from 'src/app/shared/services/error.service';
import { StripeService } from 'src/app/shared/services/stripe.service';
import { Service } from 'src/app/shared/models/service.model';

@Component({
  selector: 'sco-payment',
  templateUrl: './payment.component.html',
  styleUrls: [
    '../../../shared/components/stepper/stepper.component.scss',
    './payment.component.scss'
  ]
})
export class PaymentComponent extends StepperComponent
  implements OnInit, AfterViewInit {
  firstName = new FormControl(null, [Validators.required]);
  lastName = new FormControl(null, [Validators.required]);
  email = new FormControl(null, [
    Validators.required,
    Validators.pattern(REGULAR_EXPRESSIONS.email)
  ]);
  termsCheckbox = new FormControl(null, [Validators.requiredTrue]);
  product;
  currentService: Service;

  constructor(
    private formBuilder: FormBuilder,
    private stripeService: StripeService,
    mediaObserver: MediaObserver,
    errorService: ErrorService,
    dataService: DataService
  ) {
    super(mediaObserver, errorService, dataService);
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      formArray: this.formBuilder.array([
        this.formBuilder.group({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email
        }),
        this.formBuilder.group({
          termsCheckbox: this.termsCheckbox
        })
      ])
    });

    this.product = this.dataService.getSelectedProduct();

    if (this.product) {
      this.currentService = this.product;
    }
  }

  ngAfterViewInit() {
    // Prefill form if data exists
    const formData = JSON.parse(sessionStorage.getItem('payment'));
    if (formData) {
      this.form.setValue(formData);
      if (window.location.pathname.endsWith('/payment-success')) {
        this.bookingCompleted = true;
        this.stepper.linear = false;
        this.stepper.selectedIndex = 2;
        this.stepper.linear = true;
        sessionStorage.clear();
        window.history.replaceState('', '', '/checkout/bezahlung');
      } else if (window.location.pathname.endsWith('/payment-cancel')) {
        this.stepper.linear = false;
        this.stepper.selectedIndex = 1;
        this.stepper.linear = true;
        window.history.replaceState('', '', '/checkout/bezahlung');
      }
    }
  }

  public onPaymentClick() {
    if (this.termsCheckbox.invalid) {
      this.form.markAsDirty();
      return;
    }

    sessionStorage.setItem('payment', JSON.stringify(this.form.value));

    const requestObject = {
      payment: {
        paymentType: 'STRIPE'
      },
      customer: {
        email: this.email.value,
        firstName: this.firstName.value,
        lastName: this.lastName.value
      }
    };

    this.stripeService.redirectToStripe(
      requestObject,
      PAYMENT_PPRODUCT.sku,
      this.dataService.getSelectedProduct()
    );
  }
}
