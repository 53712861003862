import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/shared/models/service.model';
import { SERVICES } from 'src/app/shared/app-configuration/app-configuration';

@Component({
  selector: 'sco-service-overview',
  templateUrl: './service-overview.component.html',
  styleUrls: ['./service-overview.component.scss']
})
export class ServiceOverviewComponent implements OnInit {
  serviceList: Array<Service>;
  constructor() {}

  ngOnInit() {
    this.serviceList = SERVICES;
  }
}
