import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/shared/services/data.service';
import { SERVICES } from 'src/app/shared/app-configuration/app-configuration';

@Component({
  selector: 'sco-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  constructor(private router: Router, private dataService: DataService) {}

  services = SERVICES;

  ngOnInit() {
    const product = this.services.find(service => {
      return window.location.pathname.startsWith(service.bookingUrl);
    });

    if (product) {
      this.dataService.setSelectedProduct(product);
    } else {
      this.router.navigate(['/']);
    }
  }
}
