import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as firebase from 'firebase/app';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  ref = firebase.firestore().collection('mails');

  constructor() {}

  postMails(data): Observable<any> {
    return new Observable(observer => {
      this.ref.add(data).then(doc => {
        observer.next({
          key: doc.id
        });
      });
    });
  }
}
