import { Component, OnInit } from '@angular/core';
import { SERVICES } from 'src/app/shared/app-configuration/app-configuration';
import { Service } from 'src/app/shared/models/service.model';

@Component({
  selector: 'sco-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.scss']
})
export class PaymentDetailsComponent implements OnInit {
  constructor() {}
  currentService: Service;

  ngOnInit() {
    const path = window.location.pathname;
    const tmp = SERVICES.filter(service => service.detailUrl == path);
    this.currentService = tmp[0];
  }
}
