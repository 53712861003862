import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NavbarComponent } from './navbar/navbar.component';
import { MatMenuModule, MatToolbarModule } from '@angular/material';
import { SharedModule } from '../../shared/shared.module';
import { NgxPageScrollModule } from 'ngx-page-scroll';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    SharedModule,
    NgxPageScrollModule,
    RouterModule
  ],
  declarations: [HeaderComponent, NavbarComponent],
  exports: [HeaderComponent, MatToolbarModule]
})
export class HeaderModule {}
