import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { SpinnerDialogComponent } from '../components/dialogs/spinner-dialog/spinner-dialog.component';
import { Service } from '../models/service.model';

@Injectable({
  providedIn: 'root'
})
export class StripeService {
  constructor(
    private http: HttpClient,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  async triggerStripeCharge(data) {
    this.http
      .post(`${environment.apiUrl}/payment`, data)
      .subscribe((response: any) => {
        const { token, error } = stripe
          .redirectToCheckout({
            sessionId: response.paymentSessionId
          })
          .then(function(result) {
            // Display result.error.message to your customer
            return Promise.resolve();
          });
      });
  }

  redirectToStripe(request: any, sku: String, product: Service) {
    const dialogRef = this.dialog.open(SpinnerDialogComponent, {
      width: '300px',
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {});

    // Call to our backend
    this.http
      .post(`${environment.apiUrl}${product.apiEndpoint}`, request)
      .subscribe(
        (response: any) => {
          // Call stripe with document id
          const documentId = response.documentId;

          stripe
            .redirectToCheckout({
              items: [{ sku: sku, quantity: 1 }],

              // Do not rely on the redirect to the successUrl for fulfilling
              // purchases, customers may not always reach the success_url after
              // a successful payment.
              // Instead use one of the strategies described in
              // https://stripe.com/docs/payments/checkout/fulfillment
              successUrl: `${environment.baseUrl}${
                product.bookingUrl
              }/payment-success`,
              cancelUrl: `${environment.baseUrl}${
                product.bookingUrl
              }/payment-cancel`,
              customerEmail: request.customer.email,
              clientReferenceId: documentId
            })
            .then(result => {
              dialogRef.close();

              if (result.error) {
                this.snackBar.open(result.error.message, 'OK', {
                  duration: 3000
                });
              }
            });
        },
        error => {
          dialogRef.close();

          this.snackBar.open('Uups, da ist etwas schief gegangen.', 'OK', {
            duration: 3000
          });
        }
      );
  }
}
