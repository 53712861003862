import { Component, OnInit } from '@angular/core';
import { Service } from 'src/app/shared/models/service.model';
import { SERVICES } from 'src/app/shared/app-configuration/app-configuration.prod';

@Component({
  selector: 'sco-registration-details',
  templateUrl: './registration-details.component.html',
  styleUrls: ['./registration-details.component.scss']
})
export class RegistrationDetailsComponent implements OnInit {
  constructor() {}
  currentService: Service;

  ngOnInit() {
    const path = window.location.pathname;
    const tmp = SERVICES.filter(service => service.detailUrl == path);
    this.currentService = tmp[0];
  }
}
