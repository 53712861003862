import { Component, OnInit } from '@angular/core';
import { UploadService } from '../../shared/services/upload.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'sco-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements OnInit {
  progress = 0;
  docId;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uploadService: UploadService
  ) {
    const self = this;

    this.uploadService.progressChanged.subscribe(progress => {
      self.progress = progress;
    });

    // Get the docId from url params
    this.docId = this.route.snapshot.queryParamMap.get('id');

    if (!this.docId) {
      this.router.navigate(['/']);
    }
  }

  ngOnInit() {}

  public onFileChange(event) {
    const file = event.target.files[0];

    if (file && this.docId) {
      this.uploadService.uploadFile(file, this.docId);
    }
  }
}
